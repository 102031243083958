import(/* webpackMode: "eager", webpackExports: ["AutoComplete","Empty"] */ "__barrel_optimize__?names=AutoComplete,Empty!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "__barrel_optimize__?names=Checkbox!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["DatePicker","RangePicker"] */ "__barrel_optimize__?names=DatePicker!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "__barrel_optimize__?names=Input!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["InputNumber"] */ "__barrel_optimize__?names=InputNumber!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "__barrel_optimize__?names=Modal!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Select"] */ "__barrel_optimize__?names=Select!=!/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/assets/img/home/_sections/facturacion.svg");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/assets/img/home/_sections/garantia.svg");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/assets/img/home/_sections/hombre.jpg");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/assets/img/home/_sections/privacidad.svg");
;
import(/* webpackMode: "eager" */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/assets/img/home/_sections/suscripciones.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/Buttons/Button.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/Buttons/CustomButton.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/Forms/Form.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/Icon/Icon.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/Inputs/Dragger.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/moshenaftali/Documents/Mercado Azul/client-mercadoazul/src/components/RulesBasedComponents/Select/UsoCFDI.jsx");
